<template>
  <div id="container">
    <section id="register">
      <div class="wrap">

        <form action="">

          <div class="cts_box">
            <h2 class="cts_ttl"><span>お申込み内容のご確認</span></h2>

            <div class="notice no_pay mb30" v-if="current_user.paid_join_status !== 'status_failed'">
              <h3><span class="marker">ご注意！</span><br>クレジットカード情報の登録が<br class="sp">完了したにも関わらず<br>この画面が表示されている場合</h3>
              <p>
                カード決済会社側にて、<span class="red bold">お支払いの処理に時間がかかっている場合が考えられます。</span><br>
                誠に申し訳ございませんが、しばらく時間をおいてから改めて複眼経済塾にログインしてください。<br>
                <span class="size16">（注：カード決済会社によると、システム混雑の状況により<span class="red bold">過去最長３時間程度のタイムラグ</span>が発生した場合があるとの事です。）</span><br><br>
                ある程度時間が経ってもログインができない場合、<a href="/contacts/" class="bold">お問い合わせフォーム</a>より、当社事務局宛ご連絡下さい。<br>
                誠にお手数をお掛け致しますが何卒よろしくお願い申し上げます。
              </p>
            </div>

            <div class="notice no_pay mb30 status_failed" v-if="current_user.paid_join_status === 'status_failed'">
              <h3>エラー！クレジットカードの決済に失敗しました。</h3>
              <p>
                <span>カードの期限切れ</span>や<span>番号相違等</span>によりカード決済にエラーが生じております。<br/>
                カードを変更する等、<span>改めて下記ボタンより決済処理をお願い致します。</span><br/>
                決済処理が正常に完了するまで入塾手続きが終了しません。ご注意ください。
              </p>
            </div>

            <p class="alnC mb10">
              まだ支払い手続きを行っていない方はこちら
            </p>

            <div class="button_wrap">
              <a href="javascript:void(0);" class="submit basic arrow" v-on:click="retryJoinPaipent()">入塾金支払い手続き</a>
            </div>
            <div class="paygent flex">
              <img src="/common/images/paygent_logo.png" alt="PAYGENT">
              <p>
                クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
              </p>
            </div>
          </div>

          <div class="cts_box">
            <div class="confirm_list">
              <dl class="flex">
                <dt>氏名</dt>
                <dd>{{ current_user.last_name }} {{ current_user.first_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>性別</dt>
                <dd>{{ {secret: 'その他', male: '男性', female: '女性'}[current_user.gender] }}</dd>
              </dl>
              <dl class="flex">
                <dt>生年月日</dt>
                <dd>{{ moment(current_user.birthday, 'YYYY年MM月DD日') }}</dd>
              </dl>
              <dl class="flex">
                <dt>住所</dt>
                <dd>〒{{ current_user.zip }}<br>{{ current_user.address1 }} {{
                    current_user.address2
                  }}<br>{{ current_user.address3 }}
                </dd>
              </dl>
              <dl class="flex">
                <dt>ユーザーネーム（表示名）</dt>
                <dd>{{ current_user.sns_user_name }}</dd>
              </dl>
              <dl class="flex">
                <dt>メールアドレス</dt>
                <dd>{{ current_user.email }}</dd>
              </dl>
              <dl class="flex">
                <dt>電話番号</dt>
                <dd>{{ current_user.phone_number }}</dd>
              </dl>
              <dl class="flex">
                <dt>ご希望のクラス</dt>
                <dd>{{ current_user.rank_name }}</dd>
              </dl>
              <dl class="flex" v-if="false">
                <dt>複眼経済塾を<br class="pc">何で知りましたか？</dt>
                <dd></dd>
              </dl>
              <dl class="flex">
                <dt>送付先住所</dt>
                <dd>〒{{ current_user.opt_zip }}<br>{{ current_user.opt_address1 }} {{
                    current_user.opt_address2
                  }}<br>{{ current_user.opt_address3 }}
                </dd>
              </dl>
            </div>

            <p class="alnC mb10">
              まだ支払い手続きを行っていない方はこちら
            </p>
            <div class="button_wrap">
              <a href="javascript:void(0);" class="submit basic arrow" v-on:click="retryJoinPaipent()">入塾金支払い手続き</a>
            </div>
            <div class="paygent flex">
              <img src="/common/images/paygent_logo.png" alt="PAYGENT">
              <p>
                クレジットカード決済サービス会社（PAYGENT）の決済画面に移動します。PAYGENTは、NTTデータと三菱UFJニコスの合弁による国内大手決済サービス会社です。
              </p>
            </div>
            <p class="alnC mt30">
              <a href="javascript:void(0);" v-on:click="logout" class="link_txt">複眼経済塾からログアウトする</a>
            </p>
          </div>
        </form>
      </div><!--//.wrap-->
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  data() {
    return {
      fetch_data: null
    }
  },

  methods: {
    retryJoinPaipent() {
      this.axios
          .post(`${this.env.api_base_url}users/retry_join_payment.json`)
          .then((response) => {
            document.location.href = response.data.url
          })
          .catch((error) => {
            alert(error.response.data.error)
            this.logout()
          });
    },
    logout() {
      this.axios.delete(`${this.env.api_base_url}users/sign_out.json`, {})
          .then(() => {
            this.removeSession();
            this.setFlash('ログアウトしました')
            this.$router.push({
              name: 'UsersSignIn',
              //query: {sign_out: true}
            })
          })
          .catch((error) => {
            console.log(error)
          });
    }
  },
  mounted() {
    this.startAjax()
    this.axios
        .get(`${this.env.api_base_url}users/account.json`)
        .then(response => {
          // console.log(response.data)
          this.fetch_data = response.data
          this.saveSession(response.data.user);
          if (this.current_user.paid_join) {
            // 入塾金支払い済みなら、マイページへ
            this.$router.push({name: 'UsersCustomerAccount'})
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.finishAjax()
        })
  }
}
</script>

<style lang="scss">
.status_failed {
  p {
    font-weight: normal !important;
    span {
      font-weight: bold;
    }
  }
}
</style>